import React from 'react';
import { Button, Upload } from 'antd';
import shortid from 'shortid';
import firebase from 'firebase';
import { RcFile } from 'antd/lib/upload';
import { Upload as UploadIcon } from '@styled-icons/fa-solid';
import styled from 'styled-components';
import { accessToken, fetchPart } from 'shared/data/jb_api';
import useFirebase from 'vendor/Firebase';
import { useRecoilValue } from 'recoil';
import { INVENTORY_ITEMS_COLLECTION } from '../../../shared/state/inventoryState';

const UploadButton = styled(Button)`
  min-width: 240px;
  border-radius: 8px;
`;

const PartNumberUploader = () => {
  const { firestore } = useFirebase();
  const inventoryItemsDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);

  const onUpload = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result
        .split('\r')
        .slice(701);

      accessToken().then((token) => {
        data.forEach((d: string) => {
          // console.log('Fetching', d);
          fetchPart(token, d).then((partData) => {
            const dateKeys = Object.keys(partData).filter((k) => k.match(/Date/));
            const dateObject = {};
            dateKeys.forEach((k: string) => {
              // @ts-ignore
              const dateValue = new Date(partData[k]);
              // @ts-ignore
              dateObject[k] = firebase.firestore.Timestamp.fromDate(dateValue);
            });
            const newId = shortid.generate();
            const vendors = [partData.vendorCode1, partData.vendorCode2, partData.vendorCode3].filter((code: string) => code?.length > 0);
            const vendorList = vendors.map((vendor: string) => ({
              id: shortid.generate(),
              vendorCode: vendor,
              // @ts-ignore
              lastPrice: partData.purchaseCost1,
              purchasePercentage: parseFloat((1 / vendors.length).toFixed(2)),
            }));
            const newRecord = {
              ...partData,
              uniqueID: newId,
              // @ts-ignore
              price: parseFloat(partData.price1.toString()),
              // @ts-ignore
              purchaseCost: parseFloat(partData.purchaseCost1.toString()),
              // @ts-ignore
              weightedCost: parseFloat(partData.purchaseCost1.toString()),
              binLocation: partData.binLocation1 || null,
              binLot: partData.bin1Lot || null,
              binQuantity: partData.binQuantity1 || 0,
              // @ts-ignore
              quantityOnHand: parseFloat((partData.quantityOnHand1 || 0).toString()),
              purchaseQuantity: partData.purchaseQuantity1,
              vendors: vendorList,
            };
            const omitFields = [
              'price1',
              'price2',
              'price3',
              'price4',
              'price5',
              'price6',
              'price7',
              'price8',
              'purchaseCost1',
              'purchaseCost2',
              'purchaseCost3',
              'purchaseCost4',
              'purchaseCost5',
              'purchaseCost6',
              'purchaseCost7',
              'purchaseCost8',
              'purchaseQuantity1',
              'purchaseQuantity2',
              'purchaseQuantity3',
              'purchaseQuantity4',
              'purchaseQuantity5',
              'purchaseQuantity6',
              'purchaseQuantity7',
              'purchaseQuantity8',
              'quantity1',
              'quantity2',
              'quantity3',
              'quantity4',
              'quantity5',
              'quantity6',
              'quantity7',
              'quantity8',
              'binLocation1',
              'binLocation2',
              'binLocation3',
              'binLocation4',
              'binLocation5',
              'binLocation6',
              'binLocation7',
              'binLocation8',
              'bin1Lot',
              'bin2Lot',
              'bin3Lot',
              'bin4Lot',
              'bin5Lot',
              'binQuantity1',
              'binQuantity2',
              'binQuantity3',
              'binQuantity4',
              'binQuantity5',
              'vendorCode1',
              'vendorCode2',
              'vendorCode3',
            ];
            // console.log(_.omit(newRecord, omitFields));
            firestore.collection(inventoryItemsDbString).doc(shortid.generate()).set({
              ..._.omit(newRecord, omitFields),
              ...dateObject,
            }).then(() => console.log(newRecord.Sku));
          });
        });
      });
    };

    reader.readAsText(file);

    return false;
  };

  return (
    <Upload
      accept=".txt, .csv"
      showUploadList={false}
      beforeUpload={onUpload}
    >
      <UploadButton type="default" icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}>Upload Part Numbers</UploadButton>
    </Upload>
  );
};

export default PartNumberUploader;
